import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { motion } from "framer-motion";

import CampaignToolbar from "../../components/sections/CampaignToolbar";
import CampaignMeta from "../../components/sections/CampaignMeta";
import CampaignTitle from "../../components/sections/CampaignTitle";
import CampaignCard from "../../components/sections/CampaignCard";
import DevicesDropdown from "../elements/DevicesDropdown";
import DemographyDropdown from "../elements/DemographyDropdown";
import MetricBadges from "../elements/MetricBadges";
import PublisherDropdown from "../elements/PublisherDropdown";
import FrequenciesDropdown from "../elements/FrequenciesDropdown";
import LoaderDetails from "../elements/LoaderDetails";
import HelpButton from "../elements/HelpButton";

import EmotionDropdown from "../elements/EmotionDropdown";
import DemographyBadges from "../elements/DemographyBadges";
import DeviceBadges from "../elements/DeviceBadges";
import PublisherBadges from "../elements/PublisherBadges";

export default function Detail({ campaignId, videoId }) {
	const [video, setVideo] = useState(false);
	const [campaigns, setCampaigns] = useState(false);
	const [labels, setLabels] = useState(null);
	const [chartData, setChartData] = useState([]);

	const [demographics, setDemographics] = useState(null);
	const [metrics, setMetrics] = useState(null);
	const [devices, setDevices] = useState(null);
	const [publishers, setPublishers] = useState(null);
	const [frequencies, setFrequencies] = useState(null);
	const [responseChartData, setResponseChartData] = useState(null);

	const [metric, setMetric] = useState([]);
	const [demography, setDemography] = useState([]);
	const [device, setDevice] = useState([]);
	const [publisher, setPublisher] = useState([]);
	const [frequency, setFrequency] = useState([]);

	const [heatmap, setHeatmap] = useState([]);
	const [learning, setLearning] = useState([]);
	const [learningIndicators, setLearningIndicators] = useState([]);

	const [definitions, setDefinitions] = useState(null);
	const [view, setView] = useState(0);
	const [colors, setColors] = useState(null);

	// Data fetching
	//-------------------------------

	async function getVideo() {
		setChartData([]);

		const accessToken = localStorage.getItem("access_token");

		const config = {
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		};
		try {
			const response = await axios.get(`${process.env.GATSBY_API_URL}/campaigns/${campaignId}/videos/${videoId}`, config);

			setMetrics(response.data.data.emotions);
			setDemographics(response.data.data.demographics);
			setDevices(response.data.data.devices);
			setPublishers(response.data.data.publishers);
			setFrequencies(response.data.data.frequencies);
			setResponseChartData(response.data.data.charts);

			setVideo(response.data.data);
		} catch (error) {
			console.error(error);
		}
	}

	async function getDefinitions() {
		setChartData([]);

		const accessToken = localStorage.getItem("access_token");

		const config = {
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		};
		try {
			const response = await axios.get(`${process.env.GATSBY_API_URL}/definitions/`, config);

			setDefinitions(response.data.data);
		} catch (error) {
			console.error(error);
		}
	}

	async function getCampaign() {
		const accessToken = localStorage.getItem("access_token");

		const config = {
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		};
		try {
			const response = await axios.get(`${process.env.GATSBY_API_URL}/campaigns/`, config);
			setCampaigns(response.data.data);
		} catch (error) {
			console.error(error);
		}
	}

	const fetchLabels = async () => {
		const accessToken = localStorage.getItem("access_token");

		const config = {
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		};
		try {
			const response = await axios.get(`${process.env.GATSBY_API_URL}/labels`, config);
			const { data } = response.data;
			setLabels(data);
		} catch (error) {
			console.error(error);
		}
	};

	// Filter functions
	//-------------------------------

	function getChartData() {
		const emotionData = [];
		let fill = {}; // Declared at the function scope and will be used throughout

		responseChartData &&
			responseChartData.forEach(function (item) {
				// Check if the item matches the selected filters
				if (
					metric.findIndex((obj) => obj.id === item.emotion_id) !== -1 &&
					demography.findIndex((obj) => obj.id === item.demographic_id) !== -1 &&
					publisher.findIndex((obj) => obj.id === item.publisher_id) !== -1 &&
					frequency.findIndex((obj) => obj.id === item.frequency_id) !== -1 &&
					device.findIndex((obj) => obj.id === item.device_id) !== -1
				) {
					// Determine the fill color based on the current view
					if (view === 0) {
						const currentMetric = metrics.find((elem) => elem.id === item.emotion_id);
						fill = currentMetric ? currentMetric.colors : {};
					} else if (view === 1) {
						const currentDemography = demographics.find((elem) => elem.id === item.demographic_id);
						fill = currentDemography ? currentDemography.colors : {};
					} else if (view === 2) {
						const currentDevice = devices.find((elem) => elem.id === item.device_id);
						fill = currentDevice ? currentDevice.colors : {};
					} else if (view === 3) {
						const currentPublisher = publishers.find((elem) => elem.id === item.publisher_id);
						fill = currentPublisher ? currentPublisher.colors : {};
					}

					// Construct the chart data using the determined fill colors
					const center_line = {
						id: item.emotion_id,
						backgroundColor: fill.border_color,
						borderColor: fill.border_color,
						data: item.center_line,
						label: "",
						pointHoverBackgroundColor: fill.border_color,
					};
					const lower_line = {
						id: item.emotion_id,
						backgroundColor: "rgba(0,0,0,0)",
						borderColor: "rgba(0,0,0,0)",
						data: item.lower_line,
						label: "",
						pointHoverBackgroundColor: fill.border_color,
					};
					const upper_line = {
						id: item.emotion_id,
						backgroundColor: fill.background_color,
						borderColor: "rgba(0,0,0,0)",
						data: item.upper_line,
						label: "",
						pointHoverBackgroundColor: fill.background_color,
						fill: "-1",
					};

					// Add the constructed lines to the emotionData array
					emotionData.push(center_line, lower_line, upper_line);
				}
			});

		// Update the state with the new chart data
		setChartData(emotionData);
	}

	function getHeatmap() {
		const toSet = (arr) => new Set(arr?.map((d) => d?.id).filter((id) => id !== undefined));

		const [demographyIds, deviceIds, publisherIds, frequencyIds] = [demography, device, publisher, frequency].map(toSet);

		// Check if the states are not empty
		const statesNotEmpty = demographyIds.size > 0 || deviceIds.size > 0 || publisherIds.size > 0 || frequencyIds.size > 0;

		let filteredHeatmap = video?.benchmarks?.heatmaps?.filter(({ demographic_id, device_id, publisher_id, frequency_id }) => {
			const demographicMatch = demographyIds.size === 0 || demographyIds.has(demographic_id);
			const deviceMatch = deviceIds.size === 0 || deviceIds.has(device_id);
			const publisherMatch = publisherIds.size === 0 || publisherIds.has(publisher_id);
			const frequencyMatch = frequencyIds.size === 0 || frequencyIds.has(frequency_id);

			return demographicMatch && deviceMatch && publisherMatch && frequencyMatch;
		});

		// If states are not empty and there are no matches, return an empty array
		if (statesNotEmpty && filteredHeatmap.length === 0) {
			setHeatmap([]);
			return;
		}

		// If states are empty, filter to include only objects with all nulls
		if (!statesNotEmpty) {
			filteredHeatmap = video?.benchmarks?.heatmaps?.filter(({ demographic_id, device_id, publisher_id, frequency_id }) => {
				return demographic_id === null && device_id === null && publisher_id === null && frequency_id === null;
			});
		}

		setHeatmap(filteredHeatmap);
	}

	function getLearning() {
		const toNullIfZero = (id) => (id === 0 ? null : id);

		const [selectedDemographyId, selectedDeviceId, selectedPublisherId, selectedFrequencyId] = [demography, device, publisher, frequency].map(
			(arr) => toNullIfZero(arr?.[0]?.id)
		);

		const matchingLearningsWithMetric = video?.learnings?.filter((learning) => {
			const [learningDemographyId, learningDeviceId, learningPublisherId, learningFrequencyId] = [
				"demographics",
				"devices",
				"publishers",
				"frequencies",
			].map((key) => toNullIfZero(learning[key][0]));
			const isMatch =
				selectedDemographyId === learningDemographyId &&
				selectedDeviceId === learningDeviceId &&
				selectedPublisherId === learningPublisherId &&
				selectedFrequencyId === learningFrequencyId;
			const emotionMatch = metric.some((selectedEmotion) => {
				const learningEmotionId = toNullIfZero(learning.emotions[0]);
				return selectedEmotion.id === learningEmotionId;
			});
			return isMatch && emotionMatch;
		});

		const matchingLearningsWithoutMetric = video?.learnings?.filter((learning) => {
			const [learningDemographyId, learningDeviceId, learningPublisherId, learningFrequencyId] = [
				"demographics",
				"devices",
				"publishers",
				"frequencies",
			].map((key) => toNullIfZero(learning[key][0]));
			const isMatch =
				selectedDemographyId === learningDemographyId &&
				selectedDeviceId === learningDeviceId &&
				selectedPublisherId === learningPublisherId &&
				selectedFrequencyId === learningFrequencyId;
			return isMatch;
		});

		setLearningIndicators(matchingLearningsWithoutMetric);
		setLearning(matchingLearningsWithMetric);
	}

	// Handler functions
	//-------------------------------

	function clearFilter() {
		setMetric([metrics[0]]);
		setDemography([demographics[0]]);
		setDevice([devices[0]]);
		setPublisher([publishers[0]]);
		setFrequency([frequencies[0]]);
		getChartData();
	}

	function handleMetricClick(item, isBadge = false) {
		if (isBadge) {
			setMetric((currentMetrics) => {
				const index = currentMetrics.findIndex((metric) => metric.id === item.id);
				if (index !== -1) {
					return currentMetrics.filter((_, i) => i !== index);
				} else {
					return [...currentMetrics, item];
				}
			});
		} else {
			setMetric([item]);
		}
		getChartData();
	}

	function handleDemographyClick(item, isBadge = false) {
		if (isBadge) {
			setDemography((currentDemography) => {
				const index = currentDemography.findIndex((demography) => demography.id === item.id);
				if (index !== -1) {
					return currentDemography.filter((_, i) => i !== index);
				} else {
					return [...currentDemography, item];
				}
			});
		} else {
			setDemography([item]);
		}
		getChartData();
	}

	function handleDeviceClick(item, isBadge = false) {
		if (isBadge) {
			setDevice((currentDevices) => {
				const index = currentDevices.findIndex((device) => device.id === item.id);
				if (index !== -1) {
					return currentDevices.filter((_, i) => i !== index);
				} else {
					return [...currentDevices, item];
				}
			});
		} else {
			setDevice([item]);
		}
		getChartData();
	}

	function handlePublisherClick(item, isBadge = false) {
		if (isBadge) {
			setPublisher((currentPublishers) => {
				const index = currentPublishers.findIndex((publisher) => publisher.id === item.id);
				if (index !== -1) {
					// If the item is found, remove it from the array
					return currentPublishers.filter((_, i) => i !== index);
				} else {
					// If the item is not found, add it to the array
					return [...currentPublishers, item];
				}
			});
		} else {
			setPublisher([item]);
		}
		getChartData();
	}

	function handleFrequenciesClick(item) {
		setFrequency([item]);
		getChartData();
	}

	// Effects
	//-------------------------------

	useEffect(() => {
		// This effect will run when 'view' changes, ensuring that the fill colors are updated accordingly.
		if (responseChartData && responseChartData.length > 0) {
			getChartData(); // Call getChartData to update the chart data with the correct fill colors based on the current view.
		}
	}, [view]);

	useEffect(() => {
		fetchLabels();
		getVideo();
		getCampaign();
		getChartData();
		getDefinitions();
	}, [campaignId, videoId]);

	useEffect(() => {
		getHeatmap();
		getLearning();
		getChartData();
	}, [colors, metric, demography, device, publisher, frequency]);

	useEffect(() => {
		if (metrics && metrics?.length > 0) {
			setMetric([metrics[0]]);
			setColors(metrics[0].colors);
		}
		if (demographics && demographics?.length > 0) {
			setDemography([demographics[0]]);
		}
		if (devices && devices?.length > 0) {
			setDevice([devices[0]]);
		}
		if (publishers && publishers?.length > 0) {
			setPublisher([publishers[0]]);
		}
		if (frequencies && frequencies?.length > 0) {
			setFrequency([frequencies[0]]);
		}
	}, [metrics, demographics, devices, publishers, frequencies]);

	useEffect(() => {
		view === 0 && metrics && setColors(metrics[0].colors);
		view === 1 && demographics && setColors(demographics[0].colors);
		view === 2 && devices && setColors(devices[0].colors);
		view === 3 && publishers && setColors(publishers[0].colors);

		metrics && setMetric([metrics[0]]);
		demographics && setDemography([demographics[0]]);
		devices && setDevice([devices[0]]);
		publishers && setPublisher([publishers[0]]);
		frequencies && setFrequency([frequencies[0]]);
	}, [view]);

	console.clear();
	console.group("Campaign:", campaignId);
	console.log("%cVideo: ", "background-color: #ffcccc;", video);
	console.log("%cMetrics:", "background-color: #90ee90;", metrics);
	console.log("%cMetric:", "background-color: #90ee90;", metric);
	console.log("%cDemography:", "background-color: #90ee90;", demography);
	console.log("%cLearning: ", "background-color: #ffffe0;", learning);
	console.log("%cHeatmap: ", "background-color: #add8e6;", heatmap);
	console.groupEnd();

	console.log(labels);

	return (
		<main className='flex-grow h-full'>
			<CampaignToolbar campaign={video} campaigns={campaigns} />
			<section id='body' className={"gradient-bg pb-20 h-full  min-h-screen"}>
				<div className='container h-full mx-auto'>
					<div className='flex flex-wrap h-full'>
						{!video ? (
							<LoaderDetails />
						) : (
							<>
								<motion.div
									initial={{ opacity: 0, y: 20 }}
									animate={{ opacity: 1, y: 0 }}
									transition={{ duration: 0.2 }}
									className={"basis-full pt-10 pb-10"}
								>
									<CampaignTitle data={video} />
									<CampaignMeta data={video} type='detail' />
								</motion.div>
								<motion.div
									initial={{ opacity: 0, y: 20 }}
									animate={{ opacity: 1, y: 0 }}
									transition={{ duration: 0.2, delay: 0.1 }}
									className={"basis-full rounded-lg bg-white"}
								>
									<div className={"flex border-b border-[#D6DCDE] mx-5 lg:mx-[30px] mt-[15px] justify-between items-center"}>
										<div className={"basis-auto"}>
											<div className={"flex view-selector"}>
												<div className={"basis-auto pr-4 md:pr-10 mt-[1px]"}>
													<div
														onClick={() => setView(0)}
														className={`md:text-[22px] text-[#000000] cursor-pointer py-3.5 ${
															view === 0 ? "active" : ""
														}`}
													>
														Metrics
													</div>
												</div>
												<div className={"basis-auto pr-4 md:pr-10 mt-[1px]"}>
													<div
														onClick={() => setView(1)}
														className={`md:text-[22px] text-[#000000] cursor-pointer py-3.5 ${
															view === 1 ? "active" : ""
														}`}
													>
														Audiences
													</div>
												</div>
												<div className={"basis-auto pr-4 md:pr-10 md:mt-[1px]"}>
													<div
														onClick={() => setView(2)}
														className={`md:text-[22px] text-[#000000] cursor-pointer py-3.5 ${
															view === 2 ? "active" : ""
														}`}
													>
														Devices
													</div>
												</div>
												<div className={"basis-auto pr-4 md:mt-[1px]"}>
													<div
														onClick={() => setView(3)}
														className={`md:text-[22px] text-[#000000] cursor-pointer py-3.5 ${
															view === 3 ? "active" : ""
														}`}
													>
														Publishers
													</div>
												</div>
											</div>
										</div>
									</div>
									<motion.div
										initial={{ opacity: 0, y: 20 }}
										animate={{ opacity: 1, y: 0 }}
										transition={{ duration: 0.2, delay: 0.3 }}
										className='py-[30px] px-5 lg:px-[30px]'
									>
										<div className=''>
											{metrics && view === 0 && (
												<div className={"basis-full md:basis-auto"}>
													<MetricBadges
														metrics={metrics}
														metric={metric}
														heatmap={heatmap}
														learning={learning}
														learningIndicators={learningIndicators}
														onClick={handleMetricClick.bind(this)}
														onClear={clearFilter.bind(this)}
													/>
												</div>
											)}
											{demographics && view === 1 && (
												<div className={"basis-full md:basis-auto"}>
													<DemographyBadges
														demographics={demographics}
														demography={demography}
														onClick={handleDemographyClick.bind(this)}
														onClear={clearFilter.bind(this)}
													/>
												</div>
											)}
											{devices && view === 2 && (
												<div className={"basis-full md:basis-auto"}>
													<DeviceBadges
														devices={devices}
														onClick={handleDeviceClick.bind(this)}
														device={device}
														onClear={clearFilter.bind(this)}
													/>
												</div>
											)}
											{publishers && view === 3 && (
												<div className={"basis-full md:basis-auto"}>
													<PublisherBadges
														publishers={publishers}
														onClick={handlePublisherClick.bind(this)}
														publisher={publisher}
														onClear={clearFilter.bind(this)}
													/>
												</div>
											)}
										</div>

										<div className='h-[1px] my-5 border-b border-[#D6DCDE]' />

										<div className='flex items-start justify-between'>
											<div className='basis-auto'>
												<div className={"flex items-center flex-wrap gap-2"}>
													{metrics && (view === 1 || view === 2 || view === 3) && (
														<div className={"basis-1/2 md:basis-auto"}>
															<EmotionDropdown
																metrics={metrics}
																metric={metric}
																onClick={handleMetricClick.bind(this)}
															/>
														</div>
													)}
													{demographics && (view === 0 || view === 2 || view === 3) && (
														<div className={"basis-1/2 md:basis-auto"}>
															<DemographyDropdown
																demographics={demographics}
																demography={demography}
																onClick={handleDemographyClick.bind(this)}
															/>
														</div>
													)}
													{devices && (view === 0 || view === 1 || view === 3) && (
														<div className={"basis-1/2 md:basis-auto"}>
															<DevicesDropdown
																devices={devices}
																device={device}
																onClick={handleDeviceClick.bind(this)}
															/>
														</div>
													)}
													{publishers && (view === 0 || view === 1 || view === 2) && (
														<div className={"basis-1/2 md:basis-auto"}>
															<PublisherDropdown
																publishers={publishers}
																publisher={publisher}
																onClick={handlePublisherClick.bind(this)}
															/>
														</div>
													)}
													<div className={"basis-1/2 md:basis-auto"}>
														<FrequenciesDropdown
															frequencies={frequencies}
															frequency={frequency}
															onClick={handleFrequenciesClick.bind(this)}
														/>
													</div>
												</div>
											</div>
											<div className='basis-auto'>
												<HelpButton definitions={definitions} />
											</div>
										</div>
									</motion.div>

									{chartData?.length !== 0 && (
										<motion.div
											initial={{ opacity: 0, y: 20 }}
											animate={{ opacity: 1, y: 0 }}
											transition={{ duration: 0.2, delay: 0.5 }}
											className='overflow-hidden'
										>
											<CampaignCard
												video={video}
												tooltips={labels}
												metrics={metrics}
												chartData={chartData}
												details={video}
												learning={learning}
												heatmap={heatmap}
												campaignAvg={video?.benchmarks?.campaign_avg}
												campaignBenchmark={video?.benchmarks?.campaign_benchmark}
											/>
										</motion.div>
									)}
								</motion.div>
							</>
						)}
					</div>
				</div>
			</section>
		</main>
	);
}
